import React from 'react'
import Layout from '../components/layout/layout'
import PageHeader from '../components/pageheader/pageheader'
import Section from '../components/section/section'

const NewslettersPage = () => {
  return (
    <Layout>

      <PageHeader title="Newsletters" />

      <Section color="white">
        <div className="container">
          <div className="text-center">
            <h3 className="section-subheading text-muted mb-5">Check back for our quarterly newsletter which will contain useful information and chances to win fun prizes.</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-12 mb-3">
              <object data="/newsletters/Fall2021-Issue1.pdf" type="application/pdf" style={{width: '100%', height: 1000}}>
                <div>No online PDF viewer installed</div>
              </object>
            </div>

            {/* <div className="col-12">
              <h4>Past issues</h4>
              <ul>
                <li><a href="/">October 2021</a></li>
                <li><a href="/">July 2021</a></li>
              </ul>
            </div> */}
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default NewslettersPage
