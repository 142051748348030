import React from 'react'
import * as styles from './pageheader.module.scss'

const PageHeader = ({ id, title }) => {
  return <header className={`${styles.header}`}>
    <div id={id}></div>

    <div className={`${styles.headerContent}`}>
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-auto text-center">
                    <h1 className={styles.headerTitle}>{title}</h1>
                </div>
            </div>
        </div>
    </div>
  </header>
}

export default PageHeader;
